import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Cards from "../../components/Cards";
import api from "../../common/api";
import {CommonCodeValue, ErrCode, LicenseQuotaType, UserType} from "../../common/types";
import LabelText from "../../components/LabelText";
import {useTranslation} from "react-i18next";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import PropTypes from 'prop-types';
import moment from "moment";
import queryString from 'query-string';
import _ from "lodash";
import {useStores} from "../../common/store";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import {useHistory} from "react-router-dom";
import ConfirmDialog from "../../components/ConfirmDialog";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '40px 170px 60px 170px',
        [theme.breakpoints.between('xs', 'md')]: {
            margin: 20
        }
    },
    form: {
        display: 'flex',
        padding: 0,
        '& > div': {
            padding: 0,
            flexGrow: 1,
            '& > .MuiGrid-item > *': {
                backgroundColor: '#FFF'
            }
        },
        '& > div > div': {
            display: 'flex',
        }
    },
    buttonBox: {
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingRight: theme.spacing(2),
        textAlign: 'right',
        '& > *': {
            marginLeft: theme.spacing(1),
        },
        [theme.breakpoints.down('md')]: {
            marginTop: 0
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(-2),
        },
    },
}));

const alertStyles = makeStyles((theme) => ({
    alert: props => ({
            left: theme.spacing(4),
            marginBottom: theme.spacing(3),
            backgroundColor: props.level === 'warning' ? '#a78e32' : '#DE4F4F'
        })
    })
);

export default function LicenseDetailPage(props) {
    const classes = useStyles();
    const {t} = useTranslation();
    const {ds} = useStores();
    const history = useHistory();

    const {location} = props;
    const querySearch = queryString.parse(location.search);
    const seq = querySearch.seq;
    const clientKey = querySearch.clientKey;
    const userType = ds.user.getAccessClientUserType(clientKey) ?? null;

    const [revokeDialog, setRevokeDialog] = React.useState(false);
    const [licenseData, setLicenseData] = useState(null);

    const revokeOpenHandler = (value) => {
        setRevokeDialog(value);
    }

    const revoke = () => {
        api.deleteLicense(seq)
            .then((data) => {
                if (data.rtCode === ErrCode.RT_SUCCESS) {
                    // revokeOpenHandler(false);
                    ds.showInfoAlert(t('LicenseDetail.RevokeDialog.RevokeSuccess'));
                    history.push('/admin/license');
                }
            })
            .catch((err) => {
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
            });
    };

    const search = () => {
        api.getLicenseDetail(seq)
            .then((data) => {
                if (data.rtCode === ErrCode.RT_SUCCESS) {
                    setLicenseData(data.data);
                }
            })
            .catch((err) => {
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
            });
    };

    useEffect(() => {
        search();
    }, []);

    return (
        <Box className={classes.root}>
            {UserType.isSuperManager(userType) &&
            <div className={classes.buttonBox}>
                <Button color={'primary'}
                        variant="contained"
                        type={'button'}
                        onClick={() => revokeOpenHandler(true)}>
                    Revoke
                </Button>
            </div>
            }
            <form className={classes.form}>
                <Grid container spacing={5}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <LicenseStatusCard licenseData={licenseData}/>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <SpecificationsCard licenseData={licenseData}/>
                    </Grid>
                </Grid>
            </form>

            <ConfirmDialog
                open={revokeDialog}
                title={t('LicenseDetail.RevokeDialog.Title')}
                content={t('LicenseDetail.RevokeDialog.ConfirmContent')}
                onOk={() => revoke()}
                onClose={() => revokeOpenHandler(false)}
            />
        </Box>
    );
}

function SpecificationsCard({licenseData}) {
    const {t} = useTranslation();

    let client = null;
    let vendor = null;
    let contract = null;
    let quota = null;
    let period = null;
    let threshold = null;
    let createDt = null;


    if (licenseData !== null) {
        client = licenseData.clientName ?? '-';
        vendor = licenseData.vendorClientName ?? '-';
        contract = t(CommonCodeValue.translateKey + '.' + (licenseData.contractType ?? '-'));
        period = licenseData.startDt + ' ~ ' + licenseData.endDt;
        quota = t(CommonCodeValue.translateKey + '.' + (licenseData.quotaType ?? '-'));
        threshold = licenseData.maxQuota;
        createDt = _dataFormat(licenseData?.createDt, 'date', 'YYYY-MM-DD HH:mm:ss');
    }

    const specificationsInfo = [
        {label: 'LicenseDetail.Client', name: 'clientName', component: LabelText, value: client, translation: true},
        {label: 'LicenseDetail.Vendor', name: 'vendorClientName', component: LabelText, value: vendor, translation: true},
        {label: 'LicenseDetail.Contract', name: 'contract', component: LabelText, value: contract, translation: true},
        {label: 'LicenseDetail.QuotaType', name: 'quota', component: LabelText, value: quota, translation: true},
        {label: 'LicenseDetail.Threshold', name: 'threshold', component: LabelText, value: threshold, translation: true},
        {label: 'LicenseDetail.Period', name: 'period', component: LabelText, value: period, translation: true},
        {label: 'LicenseDetail.CreateDt', name: 'createDt', component: LabelText, value: createDt, translation: true}
    ];

    return (
        <Cards title={'LicenseDetail.Specifications'} components={specificationsInfo}/>
    );
}

function LicenseStatusCard({licenseData}) {
    const {t} = useTranslation();

    let seq = null;
    let status = null;
    let quota = null;
    let remained = null;

    let usage = 0;
    let usageRate = 0;

    let dayAlert = null;
    let usageAlert = null;
    let alertLevel = 'warning';

    const alertContents = [];

    if (licenseData ?? false) {
        seq = licenseData.seq ?? '-';
        quota = t(CommonCodeValue.translateKey + '.' + licenseData.quotaType ?? '-');
        status = t(CommonCodeValue.translateKey + '.' + licenseData.status ?? '-');
        remained = licenseData.reamingDays ?? 0;

        if (licenseData.maxQuota > -1) {
            if (licenseData.quotaType === LicenseQuotaType.ByAuth) {
                usage = licenseData.clientAuthCount;
            } else if (licenseData.quotaType === LicenseQuotaType.ByUser) {
                usage = licenseData.clientUserCount;
            }

            if (licenseData.maxQuota > 0) {
                usageRate = Math.round(usage / licenseData.maxQuota * 100);
            }
        }

        if (usageRate > 80) {
            usageAlert = `License expiration usage is less than ${usageRate}%`;
            alertContents.push(usageAlert);
        }

        if (remained > 0 && remained < 30) {
            dayAlert = t('LicenseDetail.ExpireWarning', {days: remained});
            alertContents.push(dayAlert);
            alertLevel = 'warning';
        } else if (remained === 0) {
            dayAlert = t('LicenseDetail.ExpireToday');
            alertContents.push(dayAlert);
            alertLevel = 'error';
        } else if (remained < 0) {
            dayAlert = t('LicenseDetail.ExpireError', {days: Math.abs(remained)});
            alertContents.push(dayAlert);
            alertLevel = 'error';
        }
    }

    const statusInfo = [
        {label: 'LicenseDetail.Seq', name: 'seq', component: LabelText, value: seq, translation: true},
        {label: 'LicenseDetail.Status', value: status, component: LabelText, translation: true},
        {label: 'LicenseDetail.QuotaType', name: 'quota', component: LabelText, value: quota, translation: true},
        {label: 'LicenseDetail.UsageRate', name: 'threshold', component: LabelText, value: usageRate + '%', translation: true},
        {label: 'LicenseDetail.Remaining', name: 'remained', component: LabelText, value: `${remained?.toLocaleString()} days`, translation: true},
        {cardType: "expireAlert", component: ExpireAlert({level: alertLevel, variant: 'filled', style: alertStyles({level: alertLevel}), contents: alertContents})},
    ];

    return (
        <Cards title={'LicenseDetail.StatusInformation'} components={statusInfo}/>
    );
}


ExpireAlert.prototype = {
    level: PropTypes.oneOf(['success', 'info', 'warning', 'error']),
    variant: PropTypes.oneOf(['standard', 'filled', 'outlined']),
}

function ExpireAlert({style, level, title, contents, variant}) {
    return (
        contents?.length > 0 ?
            contents.map((content) =>
                <Alert severity={level}
                       variant={variant}
                       className={style.alert}
                >
                    <AlertTitle className={style.title}>{title}</AlertTitle>
                    {content}
                </Alert>
            )
            : null
    );
}


function _dataFormat(data, dataType, format) {
    if (data === undefined || data === null) {
        return null;
    }
    if (dataType === 'date') {
        let dateFormat = 'YYYY-MM-DD';
        if (format !== undefined) {
            dateFormat = format;
        }
        return moment(data, dateFormat).format(dateFormat);
    } else if (dataType === 'number') {
        if (_.isNumber(data)) {
            return new Intl.NumberFormat().format(data);
        }
    }
    return data;
}
